<template>
    <div class="preview-mode">
        <component :is="component" />
    </div>
</template>
<script>
import TexasHistoryButton from '@/common/TexasHistoryButton.vue'
import LessonLayout from '@/views/LessonLayout.vue'
import Story from '@/views/Story.vue'
import LessonsDashboard from '@/views/LessonsDashboard.vue'
import UnitsDashboard from '@/views/UnitsDashboard.vue'
import UnitOverview from '@/views/UnitOverview.vue'
import Assessment from '@/views/Assessment.vue'
import Biography from '@/views/Biography.vue'
import TimeLine from '@/views/TimeLine.vue'
import TeacherPage from '@/views/TeacherPage.vue'
import ContributorsPage from '@/views/ContributorsPage.vue'
import SkillLesson from '@/views/SkillLesson.vue'
import UnitOpenerOverview from '@/views/UnitOpenerOverview'
export default {
    name:'PreviewMode', 
    components:{
        LessonLayout,
        Story,
        LessonsDashboard, 
        UnitOverview,
        Assessment,
        Biography,
        UnitsDashboard,
        TimeLine,
        TeacherPage,
        ContributorsPage,
        SkillLesson,
        UnitOpenerOverview,
        'texas-history-button': TexasHistoryButton, 
    }, 
    computed:{
      component(){
        return this.$route.meta.previewComponent
      }
    },
    created(){
        this.$store.commit('updatePreviewModeStatus', true)
    }

}
</script>

<style lang="less">
.preview-mode{
    .wrap-lesson {
    position: relative;
    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // justify-content: center;
    // margin: 0 auto;
    // z-index: 0;
    margin-top: 56px;
    height: calc(100% - 56px);
    // height: 100vh;
}
}
</style>



