<template>
    <div class="teacher-page-card column" v-if="teacherPage">
        <div class="header row ml-0">
            <img v-if="infoIcon" :src="infoIcon"> 
            <span >{{title}}</span>
            <span >{{$t('unitSkillsBanner')}}</span>
            <span class="close-icon" @click="goBack()">&times;</span>
        </div>
        <div class="content column">
            <span v-html="teacherPage.content"></span>
            <span v-if="!teacherPage.content" class="centered">{{$t('noContentToDisplayMessage')}}</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TeacherPage',
  methods:{
      goBack(){
          if(this.$store.state.isPreviewModeActive)
            return
          this.$router.go(-1)
      }
  },
  created(){
      this.$store.dispatch('loadTeacherPage', this.$route.params.teacherPageId)
  },
  computed:{
    teacherPage(){
        return this.$store.state.teacherPage
    },
    title(){
        return this.$route.query.title || ''
    },
    infoIcon(){
        return require('@/assets/icons/ic_info.svg')
    },
    closeIcon(){
        return require('@/assets/icons/ic_play.svg')
    }
  }
}
</script>

<style lang="less">
.teacher-page-card{
    margin-top: 104px;
    height: 100vh;
    .header{
        width: 100%;
        background-color: #EAEBFF;
        min-height: 66px;
        img:nth-child(1){
            margin-left: 42px;
        }

        span{
            align-self: center;
            margin-left: 14px;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            white-space: normal;
        }
        span:nth-child(2){
            color: rgba(0,0,0,0.6);
            width: 269px;
        }
        span:nth-child(3){
            color: #0A1554;
            margin-left: 175px;
        }

        .close-icon{
            margin-right: 45px;
            margin-left: auto;
            justify-self: flex-end;
            align-self: center;
            font-size: 2em;
            font-weight: 400;
            color: rgba(10, 21, 84, 0.6);
            &:hover{
                cursor: pointer;
            }
        }
        
    }
    .content{
        width: 1024px;
        margin: 19px auto;
        .column{
            position: relative;
        }

        p, span, strong em{
            color: #0A1554;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 28px;
            white-space: normal;
            overflow: initial;
        }

        strong{
            text-decoration: none;
        }
        ul:nth-child(4){
            margin-left: 40px;
        }

        ul, ol{
            padding-left: 40px;
            li{
                list-style-type: disc;
                list-style: initial;
            }
        }
        
        ol{
            li{
                list-style-type:upper-alpha;
            }
        }

        li{
            max-width: 824px;
        }
        span{
            p{
                padding: 0;
                margin: 0;
            }
        }
        
    }
}

</style>