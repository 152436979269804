var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-mode" },
    [_c(_vm.component, { tag: "component" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }