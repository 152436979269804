var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.teacherPage
    ? _c("div", { staticClass: "teacher-page-card column" }, [
        _c("div", { staticClass: "header row ml-0" }, [
          _vm.infoIcon ? _c("img", { attrs: { src: _vm.infoIcon } }) : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c("span", [_vm._v(_vm._s(_vm.$t("unitSkillsBanner")))]),
          _c(
            "span",
            {
              staticClass: "close-icon",
              on: {
                click: function($event) {
                  _vm.goBack()
                }
              }
            },
            [_vm._v("×")]
          )
        ]),
        _c("div", { staticClass: "content column" }, [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.teacherPage.content) }
          }),
          !_vm.teacherPage.content
            ? _c("span", { staticClass: "centered" }, [
                _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }